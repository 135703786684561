import { useMemo } from 'react';
import Loader from 'app/components/Loader';
import { useGetReturnedOrderRequestDetailForNotificationQuery } from 'app/modules/orders/views/OrderRequest/graphql/query/generated/getReturnedOrderRequestDetailForNotification';

import PrintReturnedOrderOutput from './printReturnedOrderOutput';
import { SnackbarService } from 'app/components/Snackbar';
import Orders from 'app/i18n/Orders';
function PrintReturnedOrderPage() {

    let orderRequestId: string = useMemo(() => { return '' }, []);
    let orderRequestItemId: string = useMemo(() => { return '' }, []);

    const params = new URLSearchParams(window.location.search);
    params.forEach((value, key) => {
        if (key === 'order_request') {
            orderRequestId = value;
        } else if (key === 'order_request_item') {
            orderRequestItemId = value;
        }
    });

    if (!orderRequestId) {
        const storageInfo = JSON.parse(localStorage.getItem('printReturnedOrderDetails') as string);
        orderRequestId = storageInfo.orderRequestId;
        orderRequestItemId = storageInfo.orderRequestItemId;
    }

    // console.log('orderRequestId', orderRequestId, 'orderRequestItemId', orderRequestItemId);
    const [{ data, fetching, error }] = useGetReturnedOrderRequestDetailForNotificationQuery({
        variables: {
            input: {
                orderRequestId: orderRequestId,
                orderRequestItemId: orderRequestItemId,
            }
        },
    });

    if (error) {
        SnackbarService.show({
            type: 'error',
            message: Orders.ErrorMessages.OrderReturnCancel,
        });
        return null;
    }

    let emailContent = null;
    if (data) {
        emailContent = JSON.parse(data.getReturnedOrderRequestDetailForNotification.templateVariables);
    }

    if (fetching) return <Loader />
    return (
        <PrintReturnedOrderOutput content={emailContent}></PrintReturnedOrderOutput>
    );
}

export default PrintReturnedOrderPage;