import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CheckInTransactionTableQueryVariables = Types.Exact<{
  filters: Types.TransactionFilters;
  sorts?: Types.InputMaybe<Array<Types.SortProps> | Types.SortProps>;
  limit?: Types.InputMaybe<Types.Scalars['Float']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type CheckInTransactionTableQuery = { __typename?: 'Query', transactions: { __typename?: 'TransactionConnection', totalCount: number, edges: Array<{ __typename?: 'TransactionEdge', node: { __typename?: 'TransactionSchema', id: string, quantity: number, entityType: Types.TransactionEntityTypeEnum, entityId: string, sourceSiteId?: string | null, sourceLocationId?: string | null, destinationSiteId?: string | null, destinationLocationId?: string | null, dueDate?: string | null, parentTransactionId: string, meta?: { __typename?: 'TransactionMetaSchema', reason?: string | null, note?: string | null, formattedNote?: string | null } | null, entity?: { __typename?: 'AssetItemSchema', id: string, sku: string, title: string, serialNumber?: string | null, description?: string | null } | { __typename?: 'AssetKitItemSchema', id: string, sku: string, title: string, description?: string | null } | { __typename?: 'InventoryItemSchema' } | { __typename?: 'InventoryKitItemSchema' } | null, destinationSite?: { __typename?: 'LocationSchema', name: string } | null, destinationLocation?: { __typename?: 'LocationSchema', name: string } | null, actor?: { __typename?: 'UserSchema', id: string, name: string } | null, sourceSite?: { __typename?: 'LocationSchema', name: string } | null, sourceLocation?: { __typename?: 'LocationSchema', name: string } | null } }>, pageInfo?: { __typename?: 'PageInfo', hasNextPage: boolean, hasPrevPage: boolean, nextCursor?: string | null, prevCursor?: string | null } | null } };


export const CheckInTransactionTableDocument = gql`
    query checkInTransactionTable($filters: TransactionFilters!, $sorts: [SortProps!], $limit: Float, $after: String, $before: String) {
  transactions(
    filters: $filters
    sorts: $sorts
    limit: $limit
    after: $after
    before: $before
  ) {
    edges {
      node {
        id
        meta {
          reason
          note
          formattedNote
        }
        quantity(pending: true)
        entityType
        entityId
        entity {
          ... on AssetItemSchema {
            id
            sku
            title
            sku
            serialNumber
            description
          }
          ... on AssetKitItemSchema {
            id
            sku
            title
            description
          }
        }
        sourceSiteId
        sourceLocationId
        destinationSiteId
        destinationLocationId
        destinationSite {
          name
        }
        destinationLocation {
          name
        }
        actor {
          id
          name
        }
        sourceSite {
          name
        }
        sourceLocation {
          name
        }
        dueDate
        parentTransactionId
      }
    }
    pageInfo {
      hasNextPage
      hasPrevPage
      nextCursor
      prevCursor
    }
    totalCount
  }
}
    `;

export function useCheckInTransactionTableQuery(options: Omit<Urql.UseQueryArgs<CheckInTransactionTableQueryVariables>, 'query'>) {
  return Urql.useQuery<CheckInTransactionTableQuery>({ query: CheckInTransactionTableDocument, ...options });
};