import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import { cx } from '@emotion/css';
import { Autocomplete, TextField } from '@procurenetworks/procure-component-library';
import {
  ItemLocationEdge,
  ItemStatusEnum,
  LocationStatusEnum,
  LocationTypeEnum,
} from 'app/types/schema';
import Box from 'app/ui-components/Box';

import FormError from '../../../../components/Form/FormError';
import FormLabel from '../../../../components/Form/FormLabel';
import Assets from '../../../../i18n/Assets';
import { useItemLocationDataQuery } from '../../../inventory/graphql/queries/generated/itemLocationData';
import { getLocationName, isUnassignedLocation } from '../../utils/location';
import { FormItemLocationOption, FormItemLocationSelectProps } from './types';
import { adaptItemLocationToOption } from './utils';
import { debounce } from 'lodash';

const getOptionLabel = (option: FormItemLocationOption) => option.name;

const FormItemLocationSelect = forwardRef<HTMLDivElement, FormItemLocationSelectProps>(
  (props, ref) => {
    const {
      className = 'mt-8',
      disabled,
      placeholder,
      value,
      onBlur,
      onChange,
      type,
      siteId,
      itemId,
      label,
      isRequired,
      error,
      includeZeroQuantity,
      name
    } = props;

    const isLocationInput = useMemo(() => {
      return type === LocationTypeEnum.Location;
    }, [type]);

    const [inputValue, setInputValue] = useState('');
    const [selectedValue, setSelectedValue] = useState<FormItemLocationOption | null>(null);
    const [debouncedSearchValue, setDebouncedSearchValue] = useState('');

    const [{ fetching: isDisabled, data: selectedData }] = useItemLocationDataQuery({
      variables: {
        filters: {
          itemLocationIds: value ? [value] : [],
          itemStatuses: [ItemStatusEnum.Active],
          locationStatuses: [LocationStatusEnum.Active],
        },
      },
      pause: !value || !!selectedValue || (isLocationInput ? !siteId || !itemId : !itemId),
    });

    useEffect(() => {
      if (!value) {
        setSelectedValue(null);
      }
    }, [value]);

    useEffect(() => {
      const selectedOption = selectedData?.itemlocations.edges[0]?.node;

      if (selectedOption) {
        const { site, location } = selectedOption;
        const { id, name: siteName } = site;
        setSelectedValue({
          id: isLocationInput ? location?.id : id,
          name: isLocationInput ? getLocationName(siteId, location) : siteName,
          location,
          site,
        });
      }
    }, [selectedData?.itemlocations.edges]);

    const search = useMemo(() => {
      if (isLocationInput && siteId && isUnassignedLocation(selectedValue?.id || '', siteId)) {
        return selectedValue?.location?.name || inputValue;
      }

      return inputValue;
    }, [isLocationInput, siteId, selectedValue, inputValue]);

    useEffect(() => {
      debounce(() => {
        setDebouncedSearchValue(search);
      }, 500);
    }, [search]);

    const [{ fetching: isLoading, data: optionsData }] = useItemLocationDataQuery({
      variables: {
        filters: {
          search: debouncedSearchValue,
          itemIds: itemId ? [itemId] : [],
          siteIds: siteId ? [siteId] : [],
          locationTypes: type && !siteId ? [type] : [],
          ...(!includeZeroQuantity
            ? {
                _or: isLocationInput
                  ? [{ nonZeroTotalQuantity: true }]
                  : [{ nonZeroTotalQuantity: true }, { nonZeroTotalQuantityFromLocations: true }],
              }
            : {}),
          itemStatuses: [ItemStatusEnum.Active],
          locationStatuses: [LocationStatusEnum.Active],
        },
        limit: 10000,
      },
      pause: isLocationInput ? !siteId || !itemId : !itemId,
    });

    const options = useMemo<FormItemLocationOption[]>(
      () =>
        adaptItemLocationToOption({
          isFromLocationInput: isLocationInput,
          itemLocationEdges: optionsData?.itemlocations.edges || ([] as ItemLocationEdge[]),
          siteId,
        }),
      [optionsData?.itemlocations.edges, isLocationInput],
    );

    const onValueChange = useCallback(
      (event: React.SyntheticEvent, option: FormItemLocationOption | null) => {
        if (option) {
          setSelectedValue(option);
          onChange?.(option.id, option);
        } else {
          setSelectedValue(null);
          onChange?.(undefined);
        }
      },
      [onChange],
    );

    const onInputValueChange = (event: React.SyntheticEvent, value: string) => {
      setInputValue(value);
    };

    return (
      <Box ref={ref} className={cx('flex flex-col', className)}>
        {label ? <FormLabel isRequired={isRequired}>{label}</FormLabel> : null}
        <Autocomplete
          className="mt-6 rounded text-13 text-[#495057] focus:border-[#80bdff] focus:outline-none"
          disabled={disabled || isDisabled || isLoading}
          getOptionLabel={getOptionLabel}
          label={''}
          loading={isLoading}
          options={options}
          renderInput={(params) => <TextField {...params} placeholder={placeholder} name={name} />}
          value={selectedValue}
          onBlur={onBlur}
          onChange={onValueChange}
          onInputChange={onInputValueChange}
        />
        <FormError error={error} />
      </Box>
    );
  },
);

interface FormSiteItemLocationSelectProps {
  control: any;
  name?: string;
  disabled?: boolean;
  isRequired?: boolean;
  itemId?: string;
  rules?: any;
  label?: string;
  onValueOptionChange?: (option: any) => void;
  includeZeroQuantity?: boolean;
}
export const FormSiteItemLocationSelect = (props: FormSiteItemLocationSelectProps) => {
  const {
    control,
    itemId,
    disabled,
    isRequired,
    name = 'skuIds',
    rules,
    label,
    onValueOptionChange,
    includeZeroQuantity,
  } = props;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <FormItemLocationSelect
          {...field}
          className="mt-8 flex-1"
          disabled={disabled}
          error={fieldState.error}
          includeZeroQuantity={includeZeroQuantity}
          isRequired={isRequired}
          itemId={itemId}
          label={label || Assets.FormLabels.FromSite}
          type={LocationTypeEnum.Site}
          onChange={(value, option) => {
            field?.onChange?.(value);
            onValueOptionChange?.(option);
          }}
        />
      )}
      rules={rules}
    />
  );
};

type FormLocationItemLocationSelectProps = FormSiteItemLocationSelectProps & {
  siteId?: string;
};

export const FormLocationItemLocationSelect = (props: FormLocationItemLocationSelectProps) => {
  const {
    control,
    itemId,
    disabled,
    siteId,
    isRequired,
    name = 'skuIds',
    label,
    rules,
    onValueOptionChange,
    includeZeroQuantity,
  } = props;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <FormItemLocationSelect
          {...field}
          className="mt-8 flex-1"
          disabled={disabled}
          error={fieldState.error}
          includeZeroQuantity={includeZeroQuantity}
          isRequired={isRequired}
          itemId={itemId}
          label={label || Assets.FormLabels.FromLocation}
          siteId={siteId}
          type={LocationTypeEnum.Location}
          onChange={(value, option) => {
            field?.onChange?.(value);
            onValueOptionChange?.(option);
          }}
        />
      )}
      rules={rules}
    />
  );
};

export default FormItemLocationSelect;
