import { OrderRequestItemSchema } from 'app/types/schema';
import FormOrderRequestItemStatus from '../FormOrderRequestItemStatus';
import { OrderDetailsData } from '../../views/OrderDetail/type';
import { useContext } from 'react';
import { OrderDetailContext } from '../../provider/OrderDetailsContext';
import { getNextValidStatus } from '../../views/OrderHistory/components/OrderStatusTableFilter/utils';

interface Props {
  orderRequestItem: OrderRequestItemSchema;
  orderDetail: OrderDetailsData;
  disabled?: boolean;
}

const OrderRequestItemStatus = (props: Props) => {
  const { orderRequestItem, orderDetail, disabled } = props;
  const { isChildTenantOrderView } = useContext(OrderDetailContext);
  let isNextValidStatusAvailable = true;
  if (!disabled) {
    isNextValidStatusAvailable = getNextValidStatus(orderRequestItem).length > 0;
  }
  return (
    <FormOrderRequestItemStatus
      orderRequestItem={orderRequestItem}
      orderDetail={orderDetail}
      disabled={disabled || !isNextValidStatusAvailable || isChildTenantOrderView}
    />
  );
};

export default OrderRequestItemStatus;
