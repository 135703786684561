import { Box } from '@mui/material';
import { DataGridHeadCell } from '@procurenetworks/procure-component-library';
import ReadMore from 'app/modules/components/ReadMore';

import { OrderRequestItemStatusEnum } from '../../../../../../types/schema';
import OrderRequestItemImageView from '../../../../components/OrderRequestItemImageView';
import OrderRequestItemSku from '../../../../components/OrderRequestItemSku';
import { findAndReturnURL, LinkUrl } from '../../../OrderDetail/utils';
import { getLeastItemStatusLabel } from '../OrderStatusTableFilter/utils';

const GREEN_COLOR_STATUS = [
  OrderRequestItemStatusEnum.Open,
  OrderRequestItemStatusEnum.BackOrdered,
  OrderRequestItemStatusEnum.Packed,
  OrderRequestItemStatusEnum.OutForDelivery,
  OrderRequestItemStatusEnum.Delivered,
  OrderRequestItemStatusEnum.Returned,
  OrderRequestItemStatusEnum.Ordered,
  OrderRequestItemStatusEnum.ReturnPacked,
  OrderRequestItemStatusEnum.ReturnOutForDelivery,
];

const RED_COLOR_STATUS = [
  OrderRequestItemStatusEnum.Cancelled,
  OrderRequestItemStatusEnum.Merged,
  OrderRequestItemStatusEnum.Closed,
];

const getClassBasedOnOrderItemStatus = (status: OrderRequestItemStatusEnum) => {
  if (GREEN_COLOR_STATUS.includes(status)) {
    return 'green';
  }
  if (RED_COLOR_STATUS.includes(status)) {
    return 'red';
  }
  return '';
};

const OrderRequestItemDescription = ({ row }: any) => {
  const text = row?.upcCode || row?.website || row?.description || row?.item?.title || '';

  // Website or description (If it contains any links)
  const textDetails = findAndReturnURL(text);

  if (textDetails?.websiteUrl !== null && text) {
    const websiteOrDescription = textDetails?.content || '';
    return (
      <Box className="w-100% truncate p-[5px] text-blue-900">
        <ReadMore
          isLinkContent
          clampLine="1"
          classNameExtends="!py-[20px]"
          redirectLink={LinkUrl(textDetails?.websiteUrl)}
          text={websiteOrDescription}
        />
      </Box>
    );
  }
  // upcCode description or website preview
  return <ReadMore clampLine="1" classNameExtends="!py-[20px]" text={text} />;
};
export const getOrderItemsColumns = (): DataGridHeadCell[] => [
  {
    id: 'imageUrl',
    label: 'Image',
    value: 'imageUrl',
    valueNode: ({ row }) => <OrderRequestItemImageView orderRequestItem={row} />,
    width: 100,
  },
  {
    id: 'sku',
    label: 'SKU',
    value: 'sku',
    valueNode: ({ row }) => {
      return <OrderRequestItemSku orderRequestItem={row} />;
    },
  },
  {
    id: 'title',
    label: 'Item Description/Title',
    value: 'title',
    valueNode: ({ row }) => <OrderRequestItemDescription row={row} />,
    width: 200,
  },
  {
    id: 'status',
    label: 'Status',
    value: 'status',
    valueNode: ({ row }) => {
      return (
        <span
          className={`truncate ${getClassBasedOnOrderItemStatus(row.status)}`}
          title={getLeastItemStatusLabel(row.status)}>
          {getLeastItemStatusLabel(row.status)}
        </span>
      );
    },
  },
];
